export function getQueryVariable(location, name) {
  if (location && typeof location.search === 'string') {
    const query = location.search.substring(1)
    const vars = query.split('&')
    for (var i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=')
      if (decodeURIComponent(pair[0]) == name) {
        return decodeURIComponent(pair[1])
      }
    }
  }
  return null
}
