/* @flow */

import * as React from 'react'

import { PayCallbackTemplate } from '../templates'
import { getQueryVariable } from './../utils'

type Props = {
  location: {
    href: string,
    search: string,
  },
}

export default function PayAccountSuccess(props: Props) {
  const user_already_exists = getQueryVariable(props.location, 'user_already_exists')
  const redirect_uri = getQueryVariable(props.location, 'redirect_uri')
  const access_token = getQueryVariable(props.location, 'access_token')
  const refresh_token = getQueryVariable(props.location, 'refresh_token')
  const login_url = getQueryVariable(props.location, 'login_url')
  const callbackLink = `${redirect_uri}?access_token=${access_token}&refresh_token=${refresh_token}&login_url=${login_url}`
  return (
    <PayCallbackTemplate
      url={props.location.href}
      name="Wino"
      title={
        user_already_exists === 'true'
          ? 'Votre compte a été associé avec succès'
          : 'Votre compte a été créé avec succès'
      }
      description="Retournez sur l'application pour finaliser la configuration de WinoPay sur votre appareil"
      callbackText={redirect_uri && 'Finaliser la configuration'}
      callbackLink={redirect_uri && callbackLink}
    />
  )
}
